import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import Layout from "../components/Layout";
import Loader from "../components/Loader";

// Lazy-loaded pages
const NewHomepage = lazy(() => import("../pages/newHomepage"));
const AboutUs = lazy(() => import("../pages/aboutus"));
const Pricing = lazy(() => import("../pages/pricing"));
const ContactSupport = lazy(() => import("../pages/contactSupport"));
const PersonalInfo = lazy(() => import("../pages/personalInfo"));
const TermsCondition = lazy(() => import("../pages/terms"));
const Privacy = lazy(() => import("../pages/privacy"));
const ContactSale = lazy(() => import("../pages/contactSale"));
const Blogs = lazy(() => import("../pages/BlogsPage"));
const BlogDetail = lazy(() => import("../pages/BlogsPage/BlogDetail"));
const SkipifyTv = lazy(() => import("../pages/skipifyTv"));
const SkipifyDetail = lazy(() => import("../pages/skipifyTv/SkipifyDetail"));
const Affiliate = lazy(() => import("../pages/affiliate"));
const FreeDownloadForm = lazy(() => import("../pages/skipifyTv/FreeDownloadForm"));
const NotFound = lazy(() => import("../pages/notfound"));
const PreForeclosre = lazy(() => import("../pages/preForeclosure/index"));


const AllRoutes = () => {
  return (
    <Layout>

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<NewHomepage title="Home" />} />
          <Route path="/about-us" element={<AboutUs title="About Us" />} />
          <Route path="/pricing-plan" element={<Pricing title="Pricing Plan" />} />
          <Route path="/contact-us" element={<ContactSupport title="Contact Support" />} />
          <Route path="/personal-info" element={<PersonalInfo title="Personal Information" />} />
          <Route path="/terms" element={<TermsCondition title="TermsCondition" />} />
          <Route path="/privacy-policy" element={<Privacy title="Privacy Policy" />} />
          <Route path="/contact-sales" element={<ContactSale title="Contact Sales" />} />
          <Route path="/blogs" element={<Blogs title="Blogs" />} />
          <Route path="/blog/:slug" element={<BlogDetail title="Blog Detail" />} />
          <Route path="/skipify-detail" element={<SkipifyDetail title="Skipify Detail" />} />
          <Route path="/skipify-u" element={<SkipifyTv title="Skipify-Tv" />} />
          <Route path="/affiliate" element={<Affiliate title="Affiliate" />} />
          <Route path="/free-download-form/:slug" element={<FreeDownloadForm title="Free Download Form" />} />
          <Route path="/404" element={<NotFound title="404" />} />
          <Route path="/*" element={<NotFound title="404" />} />
          <Route path="/pre-foreclosure" element={<PreForeclosre title="Pre Foreclosure" />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default AllRoutes;
